<template>
  <div class="container text-white">
    <div class="row">
      <div class="col-12">
        <div class="text-center mx-auto" style="max-width: 500px">
          <p class="font-weight-600">Olá! É muito bom ver você aqui!</p>
          <p>Vamos juntos para você se sentir seguro de verdade!<br /><br /></p>
        </div>

        <hr style="max-width: 100px" class="white" />

        <h2 class="mb-5 text-center text-white">
          Escolha o tipo de seguro que você precisa:
        </h2>
      </div>

  <div class="row justify-content-center">
      <InsuranceTypeCard
        :key="insuranceType.name"
        v-for="insuranceType in insuranceTypes"
        :insuranceType="insuranceType"
        :comingSoon="!insuranceType.supported"
      />
    </div>
    </div>
  </div>
</template>

<script>
import InsuranceTypeCard from "@/components/Quotation/InsuranceTypeCard";
import http from "@/services/http";
import insuranceTypesSupported from "@/enums/insuranceTypes";

export default {
  name: "QuotationHome",
  components: {
    InsuranceTypeCard,
  },
  data: () => ({
    insuranceTypes: [],
  }),
  created() {
    this.getInsuranceTypes();
  },
  methods: {
    getInsuranceTypes() {
      const insuranceTypesRoutes = {
        [insuranceTypesSupported.GUARANTEE]: {
          name: "garantee-types-selection",
        },
        [insuranceTypesSupported.RENTER_GUARANTEE]: {
          name: "renter-guarantee-quotation",
        },
        // [insuranceTypesSupported.ENGINEERING_RISK]: {
        //   name: "engineering-risk-quotation",
        // },
        // [insuranceTypesSupported.CIVIL_RESPONSABILITY]: {
        //   name: "civil-responsability-quotation",
        // },
      };

      http
        .get("insurance-types")
        .then((response) => {
          this.insuranceTypes = response.data
            .filter((insuranceType) =>
              Object.prototype.hasOwnProperty.call(
                insuranceTypesRoutes,
                insuranceType.id
              )
            )
            .map((insuranceType) => {
              const route = insuranceTypesRoutes[insuranceType.id];

              return {
                ...insuranceType,
                route,
              };
            });
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style>
</style>
